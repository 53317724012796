import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import CaseStudyImg from 'images/blog-images/drop-case-study.png';
import UsaExpressShippingImg from 'images/blog-images/usa-express-shipping.png';
import DropStepsImg from 'images/blog-images/drop-steps.png';
import CaseStudyFeatured from 'images/featured-image/drop-case-study-featured.png';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query CaseStudyImg {
      file(relativePath: { eq: "drop-case-study.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="drop case study"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Case Study: How Drop Optimizes their Shipment with Shipkoo | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-case-study" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "Case Study: How Drop Optimizes their Shipment with Shipkoo",
              content: intl.formatMessage({ id: "blog-post-meta-title-case-study" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: CaseStudyFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="case-study-how-drop-optimizes-their-shipment-with-shipkoo"
        title="Case Study: How Drop Optimizes their Shipment with Shipkoo"
        date="2020-12-09"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">

            <h5 className="no-top">
              <strong>
                Who They Are
              </strong>
            </h5>

            <p>
              Drop is an American eCommerce website and it was founded in 2012. It empowers passionate people to take part in product development, creating inventive and diverse products for a community of over 7 million people. Drop uses data and insight from the community to develop and final product that feed their interests in things like audio, mechanical keyboard, apparel, outdoors and cooking. The Drop community can also learn, share, shop and participate in product development through polls, discussions and interest check. Its logistics and fulfillment activities in China are handling by Shipkoo, which is a reliable logistics service provider to make fast deliverers on right time all over the world
            </p>

            <Image src={CaseStudyImg} className="" alt="case study drop"/>

            <h5>
              <strong>
                Challenge
              </strong>
            </h5>
            
            <p>
              Drop believes that the best way to make a good product is to tap the knowledge and ideas of passionate people. There are many challenges for Drop but the main pain point is fulfilling products to their community quickly because customer’s taste is changing day by day and the community can drive complicated designs.
            </p>

            <h5>
              <strong>
                Solution
              </strong>
            </h5>

            <p>
              By using Shipkoo’s eCommerce origin fulfillment model, Drop has been able to save both time and costs. Origin fulfillment reduced the amount of time that customers spent waiting for the product. Instead of sending a regular flow of goods to Drop’s warehouses in the US, Shipkoo sends the shipment directly from fulfillment centers in China as soon as it receives an order. End-to-end service enables customers to spend less time waiting for inventory in a US warehouse and then resending it to the customer.
            </p>

            <Image src={UsaExpressShippingImg} alt="usa express shipping"/>

            <h5>
              <strong>
                Improvement 
              </strong>
            </h5>

            <p>
              With Shipkoo, Drop has found that it has reduced warehousing costs by 60%, operations costs by 70%, transit time by 4 days and sales taxes by over 5%. The following three aspects are highly improved:
            </p>

            <Image src={DropStepsImg} alt="shipkoo drop step by step"/>

            <p>
              Drop’s product designs can be diverse and complicated, but its logistics does not have to be. Shipkoo handles the company’s shipments in a smarter way and Drop only focuses on growing business instead of worrying about back-end issues.
            </p>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="FacebookDefaultIcon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="TwitterDefaultIcon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="LinkedinDefaultIcon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;